.modal-enter {
    opacity: 0;
    transform: scale(0.9);
}
.modal-enter-done {
    opacity: 1;
    transform: scale(1);
}
.modal-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: 0.3s;
}
.modal-exit {
    opacity: 1;
}
.modal-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: 0.3s;
}
