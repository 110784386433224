* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 100%;
}
button {
    background: none;
    border: none;
}
input {
    &:focus {
        outline: none;
    }
}
