@import 'scss/variable';
.d-flex {
    display: flex;
}
.flex-1 {
    flex: 1;
}
.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.input-common {
    background: rgba(#000, 0.5);
    padding: 8px 12px;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    color: #fff;
    width: 100%;
    height: 100%;
    &::placeholder {
        font-size: 14px;
        color: #90949c;
        letter-spacing: 0;
    }
    &:focus {
        outline: none;
        border-color: var(--secondary-color);
    }
}

.bg-popup-common {
    border: 1px solid #7c94ea;
    background: var(--gradient-popup);
    padding: 20px 16px;
    border-radius: 8px;
    backdrop-filter: blur(8px);
    color: var(--white-color);
    height: 100%;
    .title {
        text-align: center;
        font-size: 18px;
    }
    .desc {
        margin: 8px 0;
    }
    .sub-title {
        font-size: 14px;
        margin: 20px 0;
        text-align: center;
    }
    .group-action {
        display: flex;
        justify-content: center;
        gap: 24px;
        margin-top: 30px;
        button {
            padding: 10px 0;
            border-radius: 8px;
            flex: 0 0 calc(50% - 12px);
            max-width: calc(50% - 12px);
        }
    }
}

.action-group-modal {
    display: flex;
    gap: 12px;
    button {
        border-radius: 8px;
        color: var(--white-color);
        font-weight: 500;
        height: 46px;
        cursor: pointer;
    }
    .btn-cancel {
        border: 1px solid var(--primary-color);
        flex: 0 0 90px;
        transition: 0.25s;
        &:hover {
            background: rgba(#030a1c, 0.5);
        }
    }
    .btn-submit {
        flex: 1;
        background: var(--primary-color);
        &:disabled {
            background: #1e1b36;
            color: #30486c;
        }
    }
}

.table-common {
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    overflow: hidden;
    &.table-col-center {
        .col {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .row {
        display: flex;
        border-bottom: 1px solid var(--border-table);
        &:first-child {
            border-bottom-color: var(--primary-color);
        }
        &:last-child {
            border-bottom: none;
        }
    }
    .col {
        flex: 1;
        padding: 12px 8px;
        text-align: center;
        font-size: 12.8px;
    }
    .text-left {
        text-align: left;
    }
    .title {
        color: var(--yellow-color);
        font-weight: 500;
        background: rgba(#000, 0.3);
    }
    .no-data {
        text-align: center;
        padding: 30px;
        height: 180px;
        p {
            margin-top: 8px;
            font-size: 14px;
        }
    }
    .btn-detail {
        color: var(--secondary-color);
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.pagination-common {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    .total {
        font-size: 14px;
        display: flex;
        align-items: center;

        p {
            color: #9a9a9a;
        }
    }
    .line {
        padding: 0 4px;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-success {
    color: var(--green-color);
}

.text-primary {
    color: var(--primary-color);
}

.text-error {
    color: var(--red-color);
}

.text-white {
    color: var(--white-color);
}

.text-secondary {
    color: var(--secondary-color);
}

.text-link {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: var(--primary-color);
    }
}

label {
    font-size: 14px;
}

select {
    background-color: var(--black-text-color);

    color: var(--white-color);
    resize: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
    opacity: 0.5;
}

::-webkit-scrollbar-thumb {
    background: #2b3853;
    opacity: 0.6;
}
