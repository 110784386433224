//fonts
html {
    --primary-color: #038787;
    --blue-color: #547dff;
    --secondary-color: #feaa31;
    --yellow-color: #fbe98b;
    --white-color: #fff;
    --red-color: #fc2569;
    // --green-color: #239b56;
    --green-color: #0fa215;
    --border-table: #2b3853;
    -gray-1: #d3d4d7;
    --black-text-color: #1a202c;
    --gradient-primary: linear-gradient(192deg, #ffad33 9.53%, #de4300 132.11%);
    --gradient-secondary: linear-gradient(180deg, #10acd3 0%, #461bf4 99.37%);
    --gradient-popup: linear-gradient(269deg, rgba(67, 47, 92, 0.74) 23.94%, rgba(21, 41, 111, 0.68) 83.16%);
    --font-lato: 'Lato';
}
