.btn-primary {
    background: var(--primary-color);
    color: var(--white-color);
    width: 100%;
    cursor: pointer;
    &:hover {
        background: #1d7777;
    }
}
.btn-secondary {
    background: var(--secondary-color);
    color: var(--black-text-color);
    width: 100%;
    &:hover {
        background: #ffc107;
    }
}
