@import 'scss/variable';
@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/common';
@import 'scss/datepicker';
@import 'scss/button';
@import 'scss/modal';

body {
    font-family: var(--font-lato), sans-serif;
    font-weight: 400;
    color: var(--black-text-color);
}

button {
    &[type='button'] {
        cursor: pointer;
    }
}

input {
    &[type='password'] {
        letter-spacing: 2px;
        &::placeholder {
            letter-spacing: 0;
        }
    }
}
