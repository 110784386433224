//font lato
@font-face {
    src: url(../assets/fonts/Lato-Light.ttf);
    font-family: Lato;
    font-weight: 300;
}
@font-face {
    src: url(../assets/fonts/Lato-Regular.ttf);
    font-family: Lato;
    font-weight: 400;
}

@font-face {
    src: url(../assets/fonts/Lato-Medium.ttf);
    font-family: Lato;
    font-weight: 500;
}

@font-face {
    src: url(../assets/fonts/Lato-Semibold.ttf);
    font-family: Lato;
    font-weight: 600;
}

@font-face {
    src: url(../assets/fonts/Lato-Bold.ttf);
    font-family: Lato;
    font-weight: 700;
}
@font-face {
    src: url(../assets/fonts/Lato-Black.ttf);
    font-family: Lato;
    font-weight: 900;
}
